<template>
  <cw-page
    hero-image="application-error.jpg"
    icon="cancel"
    class="application-not-found"
  >
    <v-row>
      <v-col>
        <p>
          <translate>
            You can start a new loan application through our website.
          </translate>
        </p>
      </v-col>
    </v-row>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="application-not-found__button"
        :href="`https://www.saldo.com/${locale}/`"
        color="primary"
        @click="$eventLogger.clickEvent($event)"
      >
        <translate>
          Apply for a loan from Saldo
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwApplicationNotFound',

  computed: {
    ...mapGetters({
      country: 'application/country',
      language: 'application/language',
    }),

    locale() {
      return `${this.country}-${this.language}`;
    },
  },
};
</script>
